<template>
  <section class="terminal-section">
    <div class="second-title content-background-title-dotted py-4 my-3 shadow">
      <h1
        class="display-8 text-center title-hero-page"
        style="
          color: var(--foreground);
          font-size: 2.5rem;
          font-family: 'Pacifico', sans-serif;
        "
      >
        Terminal <i class="bi bi-terminal" aria-hidden="true"></i>
      </h1>
      <p class="text-center text-muted">Explorez le site via ce terminal</p>
    </div>
    <Suspense>
      <template #default>
        <vue-command
          ref="terminalRef"
          :commands="commands"
          prompt="visitor:~$"
          help-text="Tapez 'help' pour voir les commandes disponibles"
          :show-help="true"
          :hide-bar="true"
          class="content-background-dotted terminal-container shadow"
        />
      </template>
      <template #fallback>
        <div class="loading-placeholder">Chargement du terminal...</div>
      </template>
    </Suspense>
  </section>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import VueCommand from "vue-command";
import "vue-command/dist/vue-command.css";
import { useTerminal } from "@/composables/useTerminal";

export default defineComponent({
  name: "TerminalComponent",
  components: { VueCommand },
  setup() {
    const { commands } = useTerminal();
    const terminalRef = ref(null);

    onMounted(() => {
      if (terminalRef.value) {
        // Logique nécessaire après le montage
      }
    });

    onUnmounted(() => {
      // Nettoyer les références lorsque le composant est démonté
      terminalRef.value = null;
    });

    return {
      commands,
      terminalRef,
    };
  },
});
</script>

<style scoped>
.terminal-container {
  height: 500px;
  margin: 0 auto;
}

.loading-placeholder {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #333;
  font-size: 1.2em;
}
</style>
