const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 heures en millisecondes

export const getFromCache = (key) => {
  try {
    const cached = localStorage.getItem(key);
    if (!cached) return null;

    const { timestamp, data } = JSON.parse(cached);
    if (Date.now() - timestamp < CACHE_DURATION) {
      return data;
    }
    // Cache expired
    localStorage.removeItem(key);
    return null;
  } catch (error) {
    console.error("Failed to retrieve from cache", error);
    return null;
  }
};

export const setInCache = (key, data) => {
  try {
    const cacheItem = {
      timestamp: Date.now(),
      data,
    };
    localStorage.setItem(key, JSON.stringify(cacheItem));
  } catch (error) {
    console.error("Failed to set cache", error);
  }
};

/**
 * A simpler version of retrieving an item from the cache.
 * @param {string} key - The cache key to retrieve.
 * @returns {*} The cached data if available and not expired, otherwise null.
 */
export function getFromCacheSimple(key) {
  const cached = localStorage.getItem(key);
  if (cached) {
    const { timestamp, data } = JSON.parse(cached);
    if (Date.now() - timestamp < CACHE_DURATION) {
      return data;
    }
    // Cache expired
    localStorage.removeItem(key);
  }
  return null;
}

/**
 * A simpler version of storing an item in the cache.
 * @param {string} key - The cache key to store.
 * @param {*} data - The data to store in the cache.
 */
export function setInCacheSimple(key, data) {
  const cacheItem = JSON.stringify({
    timestamp: Date.now(),
    data: data,
  });
  localStorage.setItem(key, cacheItem);
}
