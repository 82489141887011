<template>
  <div>
    <!-- Vérification de l'existence et de la longueur de blogContent -->
    <div v-if="blogContent && blogContent.length" class="posts row mt-3">
      <AsyncPostCard
        v-for="post in blogContent"
        :key="post.slug"
        :post="post"
      />
    </div>

    <!-- Spinner pendant le chargement -->
    <div v-if="isLoading" class="text-center mt-4">
      <SpinnerComponent />
    </div>

    <!-- Bouton Charger plus avec un spinner -->
    <div v-if="hasMorePosts && !isLoading" class="text-center mt-4">
      <button
        @click="$emit('load-more')"
        type="button"
        class="btn btn-load-more"
        :disabled="isLoading"
      >
        Charger plus
        <i class="bi bi-arrow-repeat"></i>
      </button>
    </div>

    <div v-if="!hasMorePosts" class="text-center mt-4">
      <p>Aucun autre article à afficher.</p>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import SpinnerComponent from "./SpinnerComponent.vue";
import AsyncPostCard from "./AsyncPostCard.vue";

export default {
  name: "BlogContentComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasMorePosts: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SpinnerComponent,
    AsyncPostCard,
  },
  setup() {
    const store = useStore();
    const blogContent = computed(() => store.state.blogContent);

    return {
      blogContent,
    };
  },
};
</script>

<style scoped>
.btn-load-more {
  display: inline-block;
  margin: 0 auto; /* Pour le centrer horizontalement */
  background-color: var(--pink);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-load-more:hover {
  background-color: var(--purple);
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.btn-load-more:disabled {
  background-color: var(--selection);
  cursor: not-allowed;
  box-shadow: none;
}

.btn-load-more-container {
  text-align: center; /* Pour centrer le conteneur du bouton */
  margin-top: 2rem; /* Ajouter un espace au-dessus */
}
</style>
