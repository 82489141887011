<template>
  <div class="about">
    <img
      :src="gopher"
      alt="gopher"
      height="107"
      width="100"
      class="gopher-img"
      data-aos="fade"
      title="gopher"
      loading="lazy"
    />
    <div
      class="content-background-dotted p-3 text-center pt-3 shadow about-content-section"
    >
      <template v-if="isContentLoaded">
        <div class="rounded mx-auto p-2 about-container mt-2">
          <p
            v-for="item in aboutContent"
            :key="item.id"
            class="content-mono mx-auto text-justify"
          >
            {{ item.field_334683 }}
          </p>
        </div>
        <div class="d-flex justify-content-center my-2">
          <img
            :src="stackFileUrl"
            alt="stack tech"
            class="stack-pic mb-1"
            height="100"
            width="100"
            title="stack tech"
            loading="lazy"
          />
        </div>
        <div
          v-if="aboutContent2.length"
          class="rounded mx-auto about-container"
        >
          <p
            v-for="item in aboutContent2"
            :key="item.id"
            class="content-mono mx-3 mt-4 text-justify"
          >
            <span v-if="item.field_337161">{{ item.field_337153 }}</span>
          </p>
        </div>
        <div v-else>
          <p class="text-center mt-5 text-muted">rien à voir ici ...</p>
        </div>

        <nav class="d-flex justify-content-center mt-4 mb-4 flex-wrap">
          <router-link
            v-for="link in internalLinks"
            :key="link.to"
            :to="link.to"
            class="m-2 btn btn-primary-custom btn-sm p-4"
          >
            <i
              :class="link.to === '/blog' ? 'bi bi-journal' : 'bi bi-folder'"
            ></i>
            {{ link.text }}
          </router-link>
          <a
            v-for="link in externalLinks"
            :key="link.to"
            :href="link.to"
            class="m-2 btn btn-primary-custom btn-sm p-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="bi bi-github"></i>
            {{ link.text }}
          </a>
        </nav>
      </template>
      <div v-else class="smooth">
        <p class="text-light text-center mt-3">
          Les données sont en cours de chargement ...
        </p>
        <p class="text-light text-center">
          Si rien ne s'affiche actualiser la page 👍️
        </p>
        <div class="d-flex justify-content-center mt-3">
          <SpinnerComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import loadingFile from "../assets/images/loading.gif";
import gopher from "../assets/images/gopher.webp";
import SpinnerComponent from "@/components/SpinnerComponent.vue";

export default {
  name: "AboutComponent",
  components: {
    SpinnerComponent,
  },
  setup() {
    const store = useStore();

    const aboutContent = computed(() => store.state.aboutContent);
    const aboutContent2 = computed(() => store.state.aboutContent2);
    const isContentLoaded = computed(
      () => aboutContent.value && aboutContent.value.length > 0
    );
    const stackFileUrl = computed(() =>
      isContentLoaded.value ? aboutContent.value[1].field_334759[0].url : null
    );

    const internalLinks = [
      { to: "/blog", text: "Mon Blog" },
      { to: "/projects", text: "Mes Projets" },
    ];

    const externalLinks = [
      { to: "https://github.com/benoitpetit", text: "GitHub" },
    ];

    return {
      aboutContent,
      aboutContent2,
      isContentLoaded,
      stackFileUrl,
      loadingFile,
      gopher,
      internalLinks,
      externalLinks,
    };
  },
};
</script>

<style scoped>
.about-container {
  width: 60%;
}

.stack-pic {
  width: 50%;
  height: auto;
}

.gopher-img {
  margin-left: 30px;
}

.text-justify {
  text-align: justify;
  font-size: 1rem; /* Réduire la taille de la police */
  line-height: 1.4; /* Ajuster la hauteur de ligne pour améliorer la lisibilité */
}

/* Boutons de navigation améliorés */
.btn-primary-custom {
  background-color: var(--background);
  color: var(--foreground);
  border: none;
  border-radius: 40px; /* Boutons arrondis */
  transition: all 0.3s ease-in-out; /* Transition douce pour les interactions */
  padding: 1rem; /* Augmenter le padding pour un meilleur look */
}

.btn-primary-custom:hover {
  background-color: var(--selection); /* Changer la couleur de fond au survol */
  transform: translateY(-2px); /* Effet de montée au survol */
  color: var(--foreground); /* Conserver la couleur de texte lors du survol */
}

@media screen and (max-width: 600px) {
  .about-container {
    width: 100%;
  }

  .stack-pic {
    width: 110%;
  }

  .content-mono {
    font-size: 1rem;
  }

  .btn {
    width: 100%;
  }

  .btn-sm {
    font-size: 1rem;
  }

  .about-content-section {
    width: 100%;
    padding: 0;
  }
}
</style>
