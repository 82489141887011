<template>
  <div class="header-wrapper">
    <div class="header position-relative">
      <!-- Deuxième "Hello!" rétabli sans effet -->
      <span class="hell-background-secondary">Hello!</span>

      <div
        v-if="headerContent"
        class="row d-flex align-items-center header-container"
      >
        <div
          class="col-12 col-xl-6 text-center text-xl-start header-text-section"
          data-aos="fade-right"
        >
          <p class="title-hero title-cs-xxl mb-0">
            {{ headerContent[0]?.title }}
          </p>
          <h1
            v-if="headerContent[0]?.message1 && headerContent[0]?.message2"
            class="display-8 mb-4 hell-second"
          >
            <span class="highlighted-text"
              >Moi c'est Ben <span class="wave-emoji">👋</span>
            </span>
            <br />
            <span
              ref="typingText"
              class="typing-text"
              style="font-family: 'Fira Mono', monospace"
            ></span>
          </h1>
        </div>
        <div
          class="col-12 col-xl-6 text-center header-image-section"
          data-aos="fade-left"
        >
          <img
            :src="filePicture"
            alt="Benoit Petit"
            class="img-fluid rounded-circle shadow-lg profile-picture"
            id="profile-picture"
            data-aos="zoom-out"
            height="300"
            width="300"
            title="Benoit Petit"
          />
        </div>
      </div>
      <div v-else class="smooth loading-section">
        <p class="text-light text-center mt-3">
          Les données sont en cours de chargement ...
        </p>
        <p class="text-light text-center">
          Si rien ne s'affiche, actualisez la page 👍️
        </p>
        <div class="d-flex justify-content-center mt-3">
          <SpinnerComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SpinnerComponent from "./SpinnerComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    SpinnerComponent,
  },
  data() {
    return {
      filePicture: require("@/assets/images/profil/pic.png"),
      typingTextContent: [
        "Développeur web",
        "Opensource Crafter",
        "Golang Lover",
        "Vue.js Fan",
        "Indie Hacker",
        "JavaScript Ninja",
        "API Builder",
        "Linux Enthusiast",
        "Automation Seeker",
        "Creative Coder",
      ],
      currentTextIndex: 0,
    };
  },
  computed: {
    ...mapState({
      headerContent: (state) => state.headerContent,
    }),
  },
  methods: {
    typeTextEffect() {
      const textElement = this.$refs.typingText;
      if (textElement) {
        let charIndex = 0;
        const typingSpeed = 100; // Vitesse du "taper" en ms
        const delayBetweenTexts = 2000; // Délai entre les textes

        const typeCharacter = () => {
          if (
            charIndex < this.typingTextContent[this.currentTextIndex].length
          ) {
            textElement.textContent +=
              this.typingTextContent[this.currentTextIndex].charAt(charIndex);
            charIndex++;
            setTimeout(typeCharacter, typingSpeed);
          } else {
            // Une fois le texte complet, attendre avant de passer au suivant
            setTimeout(() => {
              this.deleteTextEffect();
            }, delayBetweenTexts);
          }
        };

        typeCharacter();
      }
    },
    deleteTextEffect() {
      const textElement = this.$refs.typingText;
      if (textElement) {
        let charIndex = this.typingTextContent[this.currentTextIndex].length;

        const deleteSpeed = 50; // Vitesse de suppression en ms
        const deleteCharacter = () => {
          if (charIndex > 0) {
            textElement.textContent = textElement.textContent.slice(
              0,
              charIndex - 1
            );
            charIndex--;
            setTimeout(deleteCharacter, deleteSpeed);
          } else {
            // Passer au texte suivant
            this.currentTextIndex =
              (this.currentTextIndex + 1) % this.typingTextContent.length;
            this.typeTextEffect();
          }
        };

        deleteCharacter();
      }
    },
  },
  mounted() {
    this.typeTextEffect(); // Lancer l'effet typing à l'initialisation
  },
};
</script>

<style scoped>
/* Customs styles */
.header-wrapper {
  overflow: visible;
  position: relative;
}

.header {
  padding-top: 5%;
  position: relative;
  overflow: visible;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.title-cs-xxl {
  font-size: 10rem;
  font-weight: bold;
  color: var(--foreground);
  line-height: 1.2;
  z-index: 3;
  padding-top: 3rem;
}

.wave-emoji {
  display: inline-block;
  animation: wave 2.5s infinite ease-in-out;
  transform-origin: 70% 70%; /* Modifier l'origine pour que le geste semble naturel */
}

@keyframes wave {
  0%,
  100% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(15deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  45% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
}

.hell-background {
  position: absolute;
  top: -10px;
  left: -150px;
  opacity: 0.05;
  font-size: 22rem;
  color: #ffffff0b;
  white-space: nowrap;
  pointer-events: none;
  z-index: 1;
  font-family: "Pacifico", cursive;
}

.hell-background-secondary {
  position: absolute;
  top: -10px;
  left: -150px;
  opacity: 0.03;
  font-size: 20rem;
  color: var(--foreground);
  white-space: nowrap;
  pointer-events: none;
  z-index: 1;
  font-family: "Pacifico", cursive;
}

.hell-second {
  font-size: 2rem;
  font-weight: 500;
  color: var(--foreground);
  line-height: 1.4;
  max-width: 700px;
  margin: 0 auto;
  z-index: 3;
}

.highlighted-text {
  color: var(--pink);
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(
    90deg,
    rgba(255, 121, 198, 0.6) 0%,
    rgba(255, 121, 198, 0.1) 100%
  );
  padding: 0 10px;
  border-radius: 5px;
  transition: background-size 0.3s ease-in-out;
}

.typing-text {
  font-size: 1.8rem;
  color: var(--foreground);
  border-right: 2px solid var(--foreground);
  white-space: nowrap;
  overflow: hidden;
  animation: blink-caret 0.8s step-end infinite;
}

@keyframes blink-caret {
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: var(--foreground);
  }
}

.header-image-section {
  position: relative;
}

.profile-picture {
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.profile-picture:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
}

.loading-section {
  padding-bottom: 3rem;
}

@media screen and (max-width: 768px) {
  .title-cs-xxl {
    font-size: 6rem;
    text-align: center;
  }

  .hell-background {
    display: none;
  }

  .hell-background-secondary {
    display: none;
  }

  .hell-second {
    font-size: 1.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .profile-picture {
    height: 300px;
    width: 300px;
  }

  .title-cs-xxl {
    font-size: 5rem;
  }

  .hell-second {
    font-size: 1.5rem;
  }
}
</style>
