<template>
  <section class="projects">
    <div class="second-title content-background-title-dotted py-4 my-3 shadow">
      <h1
        class="text-center title-hero-page"
        style="
          color: var(--foreground);
          font-size: 2.5rem;
          font-family: 'Pacifico', sans-serif;
        "
      >
        Projets <i class="bi bi-kanban-fill" aria-hidden="true"></i>
      </h1>
      <p class="text-center text-muted pt-2">
        Une liste de projets indéfiniment extensible, <br />
        je continuerai demain !
      </p>
    </div>
    <div class="content-background-dotted p-2 tablflow shadow">
      <!-- Spinner pendant le chargement et si projectsContent est vide -->
      <div v-if="projectsContent.length === 0" class="spinner-container">
        <SpinnerComponent />
      </div>

      <!-- Affichage de la table des projets si les données sont disponibles -->
      <table
        v-else-if="projectsContent.length"
        class="tb-size table table-sm table-custom text-secondary"
      >
        <thead>
          <tr class="table-header text-secondary border-secondary">
            <th class="text-purple" scope="col">N°</th>
            <th class="text-purple" scope="col">Projet</th>
            <th class="text-purple" scope="col">Description</th>
            <th class="text-purple" scope="col">Stack</th>
            <th class="text-purple" scope="col">Progression</th>
            <th class="text-purple" scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in projectsContent"
            :key="item.id"
            class="table-row text-secondary border-secondary"
          >
            <td>#{{ padProjectNumber(projectsContent.length - index) }}</td>
            <td>
              <a
                :href="item.field_334715"
                target="_blank"
                rel="noopener noreferrer"
                class="link-style"
              >
                {{ item.field_334711 }}
              </a>
            </td>
            <td>{{ item.field_334714 }}</td>
            <td>
              <small class="badge badge-stack">{{ item.field_334713 }}</small>
              <div class="bdg">
                <span
                  v-for="badge in item.field_336094"
                  :key="badge.id"
                  class="badge badge-pill badge-stack m-1"
                >
                  {{ badge.value }}
                </span>
              </div>
            </td>
            <td>
              <div
                class="progress-background"
                :style="{
                  background: `linear-gradient(to right, var(--purple) ${item.field_1499401}%, var(--background) ${item.field_1499401}%)`,
                }"
              >
                {{ item.field_1499401 }}%
              </div>
            </td>
            <td>{{ formatDate(item.field_1496681) }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Message si aucun projet disponible -->
      <p v-else class="text-center text-light">
        Aucun projet disponible pour le moment.
      </p>
    </div>
  </section>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import SpinnerComponent from "@/components/SpinnerComponent.vue";

export default {
  name: "ProjectsComponent",
  components: {
    SpinnerComponent,
  },
  setup() {
    const store = useStore();

    const projectsContent = computed(() => store.state.projectsContent);

    const padProjectNumber = (num) => String(num).padStart(3, "0");

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    return {
      projectsContent,
      padProjectNumber,
      formatDate,
    };
  },
};
</script>

<style scoped>
.tablflow {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 1.8rem;
  max-height: 100rem;
  padding: 20px;
}

.table-custom {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.table-header {
  border-radius: 12px;
}

.table-row {
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.table-row:hover {
  background-color: var(--selection);
  transform: scale(1.01);
}

td,
th {
  padding: 15px;
  vertical-align: middle;
}

.text-muted {
  color: var(--comment);
}

.progress-background {
  height: 25px;
  background-color: #151515;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--foreground);
  font-weight: bold;
}

/* Spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

/* Responsive table size */
@media screen and (max-width: 600px) {
  .tb-size {
    min-width: 130vh;
  }
}
</style>
