<template>
  <div class="content-wrapper">
    <!-- Navbar améliorée -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-selection-lite shadow">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          <img
            src="https://benoitpetit.dev/favicon.ico"
            alt="Ben | Dev Web"
            style="height: 30px; width: 30px; margin-right: 10px"
          />
          Ben ✨
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          @click="toggleNavbar"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse"
          :class="{ show: isNavbarOpen }"
          id="navbarNav"
        >
          <ul class="navbar-nav ms-auto">
            <li
              v-for="(link, index) in sanitizedNavLinks"
              :key="index"
              class="nav-item m-0 p-0"
            >
              <router-link
                v-if="!link.external"
                :class="['nav-link', 'p-3', { active: isActiveRoute(link.to) }]"
                :to="link.to"
                @click="closeNavbar"
              >
                <i :class="link.icon"></i> <span>{{ link.text }}</span>
              </router-link>
              <a
                v-else
                :class="['nav-link', 'p-3']"
                :href="link.href"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i :class="link.icon"></i> <span>{{ link.text }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Consentement aux cookies -->
    <div v-if="shouldShowPopup" class="cookie-consent-overlay">
      <div class="cookie-consent-popup rounded shadow p-4">
        <p class="font-bold mb-4 text-pink" v-html="sanitizedBannerTitle"></p>
        <small
          class="content-mono mb-4 d-block"
          v-html="sanitizedBannerText"
        ></small>
        <div class="button-group mt-3">
          <button
            class="btn btn-outline-light py-2 px-3 rounded-full"
            @click="refuseAnalytics"
          >
            Refuser
          </button>
          <button
            class="btn btn-pink py-2 px-3 rounded-full"
            @click="acceptAnalytics"
          >
            Accepter
          </button>
        </div>
      </div>
    </div>

    <!-- Vue des routes -->
    <div v-if="$route.matched.length > 0" class="router-view">
      <router-view />
    </div>
    <div v-else>
      <NotFoundView></NotFoundView>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NotFoundView from "@/views/NotFoundView";
import { useHead, useSeoMeta } from "@unhead/vue";
import DOMPurify from "dompurify";

export default {
  name: "App",
  components: { NotFoundView },
  setup() {
    const store = useStore();
    const route = useRoute();
    const showPopup = ref(true);
    const title = ref("Ben | Concepteur & Développeur d'applications web");
    const description = ref(
      "Créateur et développeur d'applications web passionné. Découvrez mon blog, mes projets et mes compétences."
    );

    // État de la barre de navigation mobile
    const isNavbarOpen = ref(false);

    const navLinks = [
      { to: "/", icon: "bi bi-house-fill", text: "Accueil", external: false },
      {
        to: "/blog",
        icon: "bi bi-substack",
        text: "Blog & Tutos",
        external: false,
      },
      {
        to: "/projects",
        icon: "bi bi-kanban-fill",
        text: "Projets",
        external: false,
      },
      { to: "/sh", icon: "bi bi-terminal", text: "Terminal", external: false },
      {
        href: "https://github.com/benoitpetit",
        icon: "bi bi-github",
        text: "Github",
        external: true,
      },
    ];

    const sanitizedNavLinks = computed(() =>
      navLinks.map((link) => ({
        ...link,
        text: DOMPurify.sanitize(link.text),
      }))
    );

    const isActiveRoute = (path) => {
      if (path === "/") {
        return route.path === "/";
      }
      return route.path.startsWith(path);
    };

    const toggleNavbar = () => {
      isNavbarOpen.value = !isNavbarOpen.value;
    };

    const closeNavbar = () => {
      isNavbarOpen.value = false;
    };

    const sanitizedBannerTitle = computed(() =>
      DOMPurify.sanitize("Consentement aux cookies 🍪")
    );

    const sanitizedBannerText = computed(() =>
      DOMPurify.sanitize(
        `J'utilise des cookies pour analyser le trafic. En cliquant sur "Accepter", tu acceptes l'utilisation des cookies.`
      )
    );

    onBeforeMount(() => {
      const contentTypes = [
        "aboutContent",
        "aboutContent2",
        "experiencesContent",
        "projectsContent",
        "headerContent",
      ];
      contentTypes.forEach((type) => {
        if (!store.state[type])
          store.dispatch(`get${type.charAt(0).toUpperCase() + type.slice(1)}`);
      });
    });

    const shouldShowPopup = computed(
      () => document.cookie.indexOf("analytics=true") === -1 && showPopup.value
    );

    const acceptAnalytics = () => {
      document.cookie =
        "analytics=true; path=/; max-age=31536000; SameSite=Strict; Secure";
      showPopup.value = false;
      store.commit("setAnalytics", true);
    };

    const refuseAnalytics = () => {
      document.cookie =
        "analytics=false; path=/; max-age=31536000; SameSite=Strict; Secure";
      window.location.href = "https://duckduckgo.com";
    };

    useHead({
      link: [
        {
          rel: "canonical",
          type: "url",
          href: "https://benoitpetit.dev/",
        },
      ],
      meta: [
        {
          name: "keywords",
          content:
            "Benoit Petit, Développeur web, Node.js, Golang, Vue.js, Portfolio, Développement web, Projets web, Blog",
        },
        {
          name: "robots",
          content: "index, follow",
        },
        {
          name: "description",
          content: description.value,
        },
      ],
    });

    useSeoMeta({
      title: title.value,
      author: "Benoit Petit",
      description: description.value,
      ogUrl: "https://benoitpetit.dev",
      ogDescription: description.value,
      ogLocale: "fr_FR",
      ogLocaleAlternate: "en_US",
      ogTitle: title.value,
      ogImage: "/assets/card.png",
      ogType: "website",
      twitterCard: "/assets/card.png",
      twitterSite: "@devbyben",
      twitterDescription: description.value,
      twitterTitle: title.value,
      twitterImage: "/assets/card.png",
      twitterImageAlt: "Ben | Concepteur Développeur d'applications web",
    });

    return {
      NotFoundView,
      showPopup,
      shouldShowPopup,
      acceptAnalytics,
      refuseAnalytics,
      sanitizedNavLinks,
      sanitizedBannerTitle,
      sanitizedBannerText,
      isActiveRoute,
      isNavbarOpen,
      toggleNavbar,
      closeNavbar,
    };
  },
};
</script>

<style scoped>
/* Gestion des espacements pour empêcher la superposition de la navbar sur le contenu uniquement en mobile */
@media screen and (max-width: 600px) {
  .content-wrapper {
    padding-top: 50px;
  }
}

/* Gestion des espacements et alignement pour version mobile */
@media screen and (max-width: 600px) {
  .navbar {
    padding: 0.2rem;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .navbar-nav {
    gap: 1rem;
    padding: 1rem;
  }
  .nav-item {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .nav-link {
    font-size: 1.3rem;
    padding: 0.5rem;
  }
  .navbar-toggler {
    margin: 0.5rem 0;
  }
  .navbar-collapse.show {
    padding: 1rem;
  }
}

/* Gestion des espacements et hover pour version desktop */
.navbar {
  z-index: 7;
  padding: 0rem 1rem;
  transition: all 0.3s ease-in-out;
}

.nav-item {
  margin-left: 1rem;
}

.nav-link {
  font-size: 1rem;
  color: var(--foreground);
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
  overflow: hidden;
  padding: 0.5rem 0.75rem;
}

.nav-link:hover,
.nav-link.active {
  color: var(--pink);
  transform: translateY(-2px);
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--pink);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Overlay pour la bannière de consentement des cookies */
.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); /* Plus léger pour un effet plus discret */
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Place la popup en bas */
}

/* Popup de consentement des cookies */
.cookie-consent-popup {
  background: var(--background);
  color: var(--foreground);
  padding: 1rem;
  max-width: 400px;
  width: 90%;
  text-align: center;
  border: 1px solid var(--current_line);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  opacity: 0.95; /* Légère transparence */
}

/* Gestion des boutons pour version desktop */
.button-group {
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
}

/* En mobile, les boutons prennent toute la largeur */
@media screen and (max-width: 600px) {
  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
  }
}
</style>
