<template>
  <div
    class="not-found d-flex justify-content-center mt-5"
    data-aos="fade-down-zoom"
  >
    <img :src="notFoundPicture" alt="404 gif animation" height="350" />
  </div>
  <div class="d-flex justify-content-center">
    <a href="/" class="link-style text-center">
      cliquer ici pour revenir à la page d'accueil
    </a>
  </div>
</template>

<script>
import notFoundPicture from "../assets/images/oops.gif";

export default {
  name: "NotFoundComponent",
  data() {
    return {
      notFoundPicture,
    };
  },
};
</script>

<style scoped></style>
