<template>
  <div>
    <br />
    <span>latest posts blog 👨‍💻</span>
    <br />
    <span>-------------------</span>
    <br />
    <br />
    <span v-if="!isLoading && !isError">
      <!-- v-for -->
      <div v-for="item in resultDetails" :key="item.id">
        ✨ <a :href="item.url">{{ item.title }}</a>
        <br />
      </div>
    </span>
    <br />
    <br />
    <span v-if="isLoading && !isError">{{ loadingText }}</span>
    <span v-if="isError">There was an error getting the joke</span>
  </div>
</template>

<script lang="js">
const API_URL = 'https://dev.to/api/articles?username=benoitpetit'
//import { textFormatter } from "vue-command";

export default {
  inject: ['exit', 'signals'],

  data: () => ({
    isError: false,
    isLoading: true,
    allResult: [],
    resultDetails: [],
    loadingText: 'Loading ...'
  }),

  async mounted () {
    const abortController = new AbortController()

    const sigint = () => {
      abortController.abort()
      this.signals.off('SIGINT')
    }
    this.signals.on('SIGINT', sigint)

    try {
      const response = await fetch(API_URL, { signal: abortController.signal })

      this.signals.off('SIGINT', sigint)
      if (!response.ok) {
        this.isLoading = false
        this.isError = true
        this.exit()
        return
      }
      let data = await response.json()
      this.allResult = data
      data.forEach(element => {
        this.resultDetails.push(element)
      });
      this.isLoading = false
    } catch (error) {
      if (error.name === 'AbortError') {
        // Simulate SIGINT
        this.loadingText = `${this.loadingText}^C`
      } else {
        this.isError = true
        this.isLoading = false
      }
    } finally {
      this.signals.off('SIGINT', sigint)
      this.exit()
    }
  }
}
</script>
