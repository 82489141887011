<template>
  <div v-if="sanitizedQuote" class="quote">
    <p>
      <strong v-html="sanitizedQuote.character_name"></strong>
    </p>
    <p class="text-muted">
      <em v-html="sanitizedQuote.quote"></em>
    </p>
    <span>Dans l'épisode : </span
    ><span class="episode-name" v-html="sanitizedQuote.episode_name"></span>
    <p class="api-link">
      <a
        class="link-style"
        href="https://inside-verse-api.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Inside verse API</span>
      </a>
    </p>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import DOMPurify from "dompurify";
import { useStore } from "vuex";

const store = useStore();

onMounted(() => {
  if (!store.state.randomCharacterQuote) {
    store.dispatch("fetchRandomCharacterQuote");
  }
});

const sanitizedQuote = computed(() => {
  const quote = store.state.randomCharacterQuote;
  if (!quote) return null;

  return {
    character_name: DOMPurify.sanitize(quote.character_name),
    quote: DOMPurify.sanitize(quote.quote),
    episode_name: DOMPurify.sanitize(quote.episode_name),
  };
});
</script>

<style scoped>
.quote {
  border: 1px solid var(--current_line);
  padding: 10px;
  background-color: var(--background);
  border-radius: 5px;
  width: 40%;
  margin: 60px auto 0;
  color: #adadad;
}

@media screen and (max-width: 600px) {
  .quote {
    width: 100%;
  }
}

.quote p {
  font-size: 16px;
  margin-bottom: 5px;
}

.episode-name {
  font-style: italic;
}

.api-link {
  text-align: right;
}

.quote small:first-child {
  font-size: 12px;
  color: #cfcfcf;
}
</style>
