<template>
  <div class="home container" data-aos="fade-zoom" data-aos-delay="100">
    <div class="mt-5 second-sector">
      <ProjectsComponent></ProjectsComponent>
    </div>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import ProjectsComponent from "@/components/ProjectsComponent";
import FooterComponent from "@/components/FooterComponent";
import { ref } from "vue";
import { useSeoMeta } from "@unhead/vue";

export default {
  name: "ExperiencesView",
  components: {
    FooterComponent,
    ProjectsComponent,
  },
  setup() {
    const title = ref("Ben | Projects & Packages");
    const description = ref(
      `Plongez au cœur des concrétisations issues de mon engagement et de ma passion dédiée à la création de programmes.`
    );
    useSeoMeta({
      title: title,
      description: description,
      ogTitle: title,
      ogDescription: description,
      ogUrl: "https://benoitpetit.dev/projects",
      ogSiteName: title,
      twitterDescription: description,
      twitterTitle: title,
    });
  },
};
</script>
