import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
// import ExperiencesView from "@/views/ExperiencesView";
import ProjectsView from "@/views/ProjectsView";
import BlogView from "@/views/BlogView";
import PostView from "@/views/PostView";
import NotFoundView from "@/views/NotFoundView";
import ShView from "@/views/ShView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/experiences&degrees",
  //   name: "experiences&degrees",
  //   component: ExperiencesView,
  // },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/blog/:slug",
    name: "blog-post",
    component: PostView,
  },
  {
    path: "/sh",
    name: "sh",
    component: ShView,
  },
  {
    path: "/*",
    redirect: "Notfound",
  },
  {
    path: "/404",
    name: "Notfound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // Si un savedPosition existe, utilisez-le (navigation avant/arrière)
      return savedPosition;
    } else {
      // Sinon, faites défiler vers le haut de la page
      return { top: 0, behavior: "smooth" };
    }
  },
});

export default router;
