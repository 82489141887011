<template>
  <div class="about container">
    <NotFoundComponent></NotFoundComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import NotFoundComponent from "@/components/NotFoundComponent";
import FooterComponent from "@/components/FooterComponent";
export default {
  name: "NotFoundView",
  components: { FooterComponent, NotFoundComponent },
};
</script>
