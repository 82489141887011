import { ref } from "vue";
import { useRouter } from "vue-router";
import { createStdout, jsonFormatter, textFormatter } from "vue-command";
import chuck from "@/components/GetblogTeminalComponent.vue";

export function useTerminal() {
  const router = useRouter();
  const terminalRef = ref(null);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const commands = {
    cat: () => createStdout("🐈‍⬛ Miaw !"),
    blog: () => chuck,
    secret: () => {
      window.open("https://v1.windows93.net/", "_blank");
      return createStdout("You found the secret command, congrats!");
    },
    social: () =>
      createStdout(
        textFormatter(
          `
          follow me on social networks 😎
          --------------------

          > <a href='https://www.benoitpetit.dev' target="_blank" rel="noopener noreferrer">Website</a>
          > <a href='https://github.com/benoitpetit' target="_blank" rel="noopener noreferrer">Github</a>
          > <a href='https://gitlab.com/petitbenoit' target="_blank" rel="noopener noreferrer">Gitlab</a>
          > <a href='https://mas.to/@bs7416' target="_blank" rel="noopener noreferrer">Mastodon</a> 
          > <a href='https://www.npmjs.com/~codingben' target="_blank" rel="noopener noreferrer">NPM</a>
          > <a href='https://t.me/benoitpetit' target="_blank" rel="noopener noreferrer">Telegram</a>
        `,
          { isHtml: true }
        )
      ),
    about: () =>
      createStdout(
        jsonFormatter({
          true: "Je code comme si ma vie en dépendait",
          name: "Benoit Petit",
          age: calculateAge("1988-03-12"),
          job: "Concepteur & Développeur d'Applications",
          location: "Lille, France",
          tech: "Node.js, Vue.js, Golang, Docker, Linux, Github",
        })
      ),
    clear: () => {
      location.reload();
    },
    help: () =>
      createStdout(`
        available commands ✨
        --------------------

        > about => show about
        > blog => show latest blog posts
        > social => show social links
        > cat => show cat
        > help => show help
        > clear => clear terminal
        > exit => exit terminal
        ...

      `),
    exit: () => {
      router.push("/");
    },
  };

  return {
    commands,
    terminalRef,
  };
}
