<template>
  <div class="home container" data-aos="fade-down-zoom">
    <div class="mt-5 second-sector">
      <TerminalComponent></TerminalComponent>
      <FooterComponent></FooterComponent>
    </div>
  </div>
</template>

<script lang="js">
import TerminalComponent from '@/components/TerminalComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue'
import { ref } from 'vue';
import { useSeoMeta } from '@unhead/vue';

export default {
  name: 'ShView',
  components: {
    FooterComponent,
    TerminalComponent,
  },
  setup() {
    const title = ref("Ben | Terminal website");
    const description = ref(
      `Ici, vous pouvez naviguer à travers mon site en ligne de commande, une expérience immersive pour les amateurs de CLI et les adeptes de la simplicité.`
    );
    useSeoMeta({
      title: title,
      description: description,
      ogTitle: title,
      ogDescription: description,
      ogUrl: "https://benoitpetit.dev/sh",
      ogSiteName: title,
      twitterDescription: description,
      twitterTitle: title,
    });
  },
}
</script>

<style scoped>
div,
textarea {
  height: 100%;
}
</style>
