<template>
  <div class="col-xs-12 col-sm-6 col-md-4 col-xl-3 p-0 mt-3">
    <article class="card content-background-dotted m-2 p-0 shadow">
      <router-link
        :to="{ name: 'blog-post', params: { slug: post.slug } }"
        class="card-img-link"
      >
        <div class="image-container">
          <img
            :src="post.coverImage.url"
            class="card-img-top"
            :alt="post.title"
            loading="lazy"
          />
        </div>
      </router-link>
      <div class="card-body card-content-post">
        <h2 class="h5">
          <router-link
            :to="{ name: 'blog-post', params: { slug: post.slug } }"
            class="link-style"
          >
            {{ post.title }}
          </router-link>
        </h2>

        <!-- Réduction de la taille du texte de brief -->
        <p class="card-text brief-text mb-0">{{ post.brief }}</p>

        <!-- Amélioration des tags -->
        <div class="tag-container mt-2">
          <span v-for="tag in post.tags" :key="tag.name" class="tag-badge">
            {{ tag.name }}
          </span>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "AsyncPostCard",
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Contenir les images dans la bordure */
.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Ratio 16:9 */
  overflow: hidden;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 1.2rem 1.2rem 0 0;
}

.card-img-link:hover .image-container img {
  transform: scale(1.05);
}

.card {
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  border-radius: 1.2rem;
  overflow: hidden;
}

.card-body {
  padding: 1rem;
}

.card-text {
  font-size: 1.1rem;
  color: var(--text-muted);
}

/* Réduction et gestion du post.brief */
.brief-text {
  color: var(--comment);
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limite le texte à 2 lignes */
  line-clamp: 4; /* Limite le texte à 2 lignes */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

/* Améliorer la gestion des tags */
.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.tag-badge {
  background-color: var(--selection);
  color: var(--foreground);
  padding: 0.3rem 0.6rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.tag-badge:hover {
  background-color: var(--purple);
  color: var(--background);
  transform: translateY(-1px); /* Légère élévation au survol */
  cursor: default; /* Pointeur normal */
}

/* Ajouter un style responsive pour les tags */
@media screen and (max-width: 600px) {
  .tag-badge {
    font-size: 0.75 rem;
  }
}
</style>
