<template>
  <div
    class="newsletter-form card content-background-dotted p-0 shadow mt-5 d-flex justify-content-center"
  >
    <div class="card-body p-4">
      <h3
        class="text-center"
        style="
          color: var(--foreground);
          font-size: 2.5rem;
          font-family: 'Pacifico', sans-serif;
        "
      >
        Abonnez-vous à ma newsletter
      </h3>
      <p class="text-center" style="color: var(--comment); font-size: 1rem">
        Recevez mes derniers articles et astuces directement dans votre boîte
        mail.
      </p>
      <div v-if="!formSubmitted">
        <form
          @submit.prevent="handleNewsletterSubmit"
          class="newsletter-form-container"
        >
          <div class="input-group mb-3 newsletter-input-group">
            <input
              v-model="newsletterEmail"
              type="email"
              class="form-control newsletter-input"
              placeholder="Votre adresse e-mail"
              required
            />
            <button type="submit" class="btn btn-primary newsletter-btn">
              S'abonner
            </button>
          </div>

          <div
            class="form-check mb-3 newsletter-checkbox-group d-flex justify-content-center"
          >
            <input
              v-model="newsletterAgreement"
              type="checkbox"
              class="form-check-input"
              id="newsletterAgreement"
              required
            />
            <label
              class="form-check-label newsletter-checkbox-label"
              for="newsletterAgreement"
            >
              J'accepte de recevoir des e-mails de la part de ce blog.
            </label>
          </div>

          <p class="text-muted text-center" style="font-size: 0.85rem">
            powered by
            <a
              href="https://hashnode.com"
              target="_blank"
              style="color: var(--purple); text-decoration: none"
              >Hashnode</a
            >
          </p>
        </form>
      </div>

      <p
        v-if="newsletterStatus"
        class="text-center mt-3"
        :style="{ color: newsletterStatusColor }"
      >
        {{ newsletterStatusMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getFromCacheSimple, setInCacheSimple } from "../utils/cache";

export default {
  name: "NewsletterComponent",
  setup() {
    const newsletterEmail = ref("");
    const newsletterAgreement = ref(false);
    const newsletterStatus = ref("");
    const newsletterStatusMessage = ref("");
    const newsletterStatusColor = ref("var(--foreground)");
    const formSubmitted = ref(false);

    const handleNewsletterSubmit = async () => {
      if (!newsletterAgreement.value) {
        newsletterStatusMessage.value =
          "Veuillez accepter de recevoir des e-mails.";
        newsletterStatusColor.value = "var(--red)";
        newsletterStatus.value = true;
        return;
      }

      const cacheKey = `newsletter_${newsletterEmail.value}`;
      const cachedResponse = getFromCacheSimple(cacheKey);
      if (cachedResponse) {
        newsletterStatusMessage.value = cachedResponse.message;
        newsletterStatusColor.value = cachedResponse.color;
        formSubmitted.value = true;
        return;
      }

      try {
        const response = await fetch(
          "/.netlify/functions/subscribeNewsletter",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: newsletterEmail.value,
              consent: newsletterAgreement.value,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          let message = "";
          let color = "";
          switch (data.status) {
            case "CONFIRMED":
              message = "Merci de vous être abonné !";
              color = "var(--green)";
              break;
            case "PENDING":
              message =
                "Vérifiez votre e-mail pour confirmer votre abonnement.";
              color = "var(--yellow)";
              break;
            case "ALREADY_SUBSCRIBED":
              message = "Vous êtes déjà abonné à la newsletter.";
              color = "var(--orange)";
              break;
            default:
              message =
                "Une erreur est survenue. Veuillez réessayer plus tard.";
              color = "var(--red)";
          }
          setInCacheSimple(cacheKey, { message, color });
          newsletterStatusMessage.value = message;
          newsletterStatusColor.value = color;
          newsletterEmail.value = "";
          newsletterAgreement.value = false;
          formSubmitted.value = true;
        } else {
          throw new Error(data.message || "Une erreur est survenue");
        }
      } catch (error) {
        newsletterStatusMessage.value =
          "Une erreur est survenue. Veuillez réessayer plus tard.";
        newsletterStatusColor.value = "var(--red)";
      }
      newsletterStatus.value = true;
    };

    return {
      newsletterEmail,
      newsletterAgreement,
      newsletterStatus,
      newsletterStatusMessage,
      newsletterStatusColor,
      handleNewsletterSubmit,
      formSubmitted,
    };
  },
};
</script>

<style scoped>
.newsletter-form {
  border-radius: 15px;
  background-color: var(--background);
}

.newsletter-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-input-group {
  max-width: 500px;
  width: 100%;
}

.newsletter-input {
  background-color: var(--background);
  color: var(--foreground);
  border: 1px solid var(--selection);
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  /* Forcer l'arrondi en priorisant les styles */
}

.newsletter-input::content {
  color: red;
}

.newsletter-input:focus {
  border-color: var(--purple);
  box-shadow: 0px 0px 10px 2px var(--purple);
  border-top-left-radius: 50px !important; /* Forcer l'arrondi au focus */
  border-bottom-left-radius: 50px !important;
}

.newsletter-btn:hover {
  /* transform: translateY(-3px); */
  box-shadow: 0px 0px 15px 2px var(--pink);
  background-color: var(--purple);
}

.newsletter-btn {
  background-color: var(--purple);
  border: none;
  color: var(--foreground);
  padding: 0.6rem 1.5rem;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.newsletter-checkbox-group {
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
}

.newsletter-checkbox-label {
  color: var(--foreground);
  margin-left: 0.5rem;
  font-size: 0.9rem;
}
</style>
