<template>
  <div
    class="spinner-container d-flex justify-content-center align-items-center"
  >
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpinnerComponent",
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.spinner-border {
  width: 3.5rem;
  height: 3.5rem;
  border-width: 0.3rem;
}

.spinner-border.text-light {
  border-color: var(--pink) transparent var(--pink) transparent;
}

.spinner-border.text-light::before {
  content: "";
  display: block;
  width: 80%;
  height: 80%;
  border: 3px solid var(--purple);
  border-radius: 50%;
  position: absolute;
}
</style>
