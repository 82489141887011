<template>
  <div class="container mt-4" data-aos="fade-zoom" data-aos-delay="100">
    <!-- Composant Newsletter -->
    <NewsletterComponent />
    <hr style="color: var(--foreground)" />

    <!-- Contenu du blog -->
    <div data-aos="fade-zoom" data-aos-delay="100">
      <BlogContentComponent
        :isLoading="isLoading"
        :hasMorePosts="hasMorePosts"
        @load-more="loadMorePosts"
      />
    </div>

    <!-- Composant Footer -->
    <FooterComponent />
    <input type="text" name="honeypot" id="honeypot" style="display: none" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useSeoMeta } from "@unhead/vue"; // Importer la gestion du SEO
import BlogContentComponent from "@/components/BlogContentComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import NewsletterComponent from "@/components/NewsletterComponent.vue";

export default {
  name: "BlogView",
  components: {
    BlogContentComponent,
    FooterComponent,
    NewsletterComponent,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const hasMorePosts = ref(true);

    // Fonction pour charger le contenu du blog
    const loadBlogContent = async (reset = false) => {
      isLoading.value = true;
      await store.dispatch("getBlogContent", { reset });
      hasMorePosts.value = store.state.hasMorePosts;
      isLoading.value = false;
    };

    // Fonction pour charger plus d'articles
    const loadMorePosts = async () => {
      if (hasMorePosts.value && !isLoading.value) {
        await loadBlogContent(false);
      }
    };

    // Charger les articles lors du montage de la vue
    onMounted(() => {
      if (store.state.blogContent.length === 0) {
        loadBlogContent(true);
      }
    });
    useSeoMeta({
      title: "Ben | Blog & Tutos - Derniers articles et tutoriels",
      description:
        "Découvrez les derniers articles et tutoriels de développement web, Node.js, Vue.js, Golang et bien plus encore. Apprenez à travers des projets réels et des conseils pratiques.",
      ogTitle: "Ben | Blog & Tutos",
      ogDescription:
        "Explorez une variété d'articles et de tutoriels sur les technologies de développement web. Apprenez Node.js, Vue.js, Docker, et d'autres outils de développeurs.",
      ogUrl: "https://benoitpetit.dev/blog",
      ogImage: "/assets/card.png",
      ogSiteName: "Ben | Blog & Tutos",
      publisher: "Benoit Petit",
      ogType: "website",
      twitterCard: "/assets/card.png",
      twitterTitle: "Ben | Blog & Tutos - Derniers articles",
      twitterDescription:
        "Apprenez le développement web à travers des articles et tutoriels sur Node.js, Vue.js, Docker, et d'autres outils techniques.",
      twitterImage: "/assets/card.png",
      twitterSite: "@devbyben",
      robots: "index, follow",
      twitterCreator: "@devbyben",
    });

    return {
      isLoading,
      hasMorePosts,
      loadMorePosts,
    };
  },
};
</script>
