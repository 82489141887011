<template>
  <footer class="footer mt-5 pt-3">
    <div class="container">
      <hr class="divider" />
      <div class="row my-5">
        <div class="col-md-4">
          <h2 class="title-second">Qui suis-je ?</h2>
          <hr class="divider" />
          <p class="text-muted">
            Moi c'est Ben, Concepteur & Développeur d'applications freelance
            basé à Lille, France. Passionné par la tech, la programmation, et la
            collection de stickers de dev.
          </p>
        </div>
        <div class="col-md-4">
          <h2 class="title-second">Compétences</h2>
          <hr class="divider" />
          <ul class="skills-list">
            <li v-for="skill in skills" :key="skill.name" class="skill-item">
              <i :class="skill.icon" class="me-2"></i>{{ skill.name }}
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <h2 class="title-second">Contact</h2>
          <hr class="divider" />
          <p class="text-muted">
            Vous souhaitez me contacter pour poser des questions, partager vos
            commentaires, demander des conseils ou discuter d'un projet ?
          </p>
          <button
            class="btn btn-pink contact-btn"
            data-tally-open="mBMX7n"
            data-tally-layout="modal"
            data-tally-width="502"
            data-tally-emoji-text="🤘 "
            data-tally-emoji-animation="bounce"
            data-tally-auto-close="3000"
          >
            Contactez-moi
          </button>
        </div>
      </div>
      <hr class="divider" />
      <div class="social-links text-center my-4">
        <h2 class="title-second text-muted mb-3">Retrouvez-moi sur</h2>
        <div class="d-flex justify-content-center flex-wrap">
          <a
            v-for="link in socialLinks"
            :key="link.url"
            :href="link.url"
            target="_blank"
            class="social-icon"
          >
            <i :class="link.icon"></i>
          </a>
        </div>
      </div>
      <hr class="divider" />
      <div class="text-center mt-4">
        <img
          :src="powered"
          height="30"
          width="326"
          alt="powered logo"
          class="powered mt-1"
          title="powered logo"
        />
        <p class="text-muted mt-2">
          code with ❤️ by
          <a href="https://x.com/@devbyben" class="link-style" target="_blank"
            >Benoit Petit</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { reactive } from "vue";
import powered from "../assets/images/powered.webp";

export default {
  name: "FooterComponent",
  setup() {
    const skills = reactive([
      {
        name: "Node.js",
        icon: "bi bi-hdd-network",
      },
      {
        name: "Golang",
        icon: "bi bi-cpu",
      },
      {
        name: "Vue.js",
        icon: "bi bi-diagram-3",
      },
      {
        name: "Docker",
        icon: "bi bi-boxes",
      },
      {
        name: "Automatisation",
        icon: "bi bi-gear",
      },
      {
        name: "Web Scraping",
        icon: "bi bi-funnel",
      },
      {
        name: "Linux",
        icon: "bi bi-terminal",
      },
    ]);

    const socialLinks = [
      { url: "https://x.com/devbyben", icon: "bi bi-twitter-x" },
      { url: "https://t.me/benoitpetit", icon: "bi bi-telegram" },
      {
        url: "https://www.linkedin.com/in/benoit-petit-110",
        icon: "bi bi-linkedin",
      },
      { url: "https://github.com/benoitpetit", icon: "bi bi-github" },
      {
        url: "https://www.npmjs.com/~codingben",
        icon: "bi bi-box2-heart-fill",
      },
      {
        url: "https://blog.benoitpetit.dev/newsletter",
        icon: "bi bi-substack",
      },
    ];

    return {
      powered,
      skills,
      socialLinks,
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: var(--background);
  color: var(--foreground);
  padding: 2rem 0;
}

.divider {
  border-color: var(--foreground);
  opacity: 0.2;
  margin: 0 0 1rem;
}

.title-second {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--foreground);
}

.text-muted {
  font-size: 0.95rem;
  color: var(--comment);
}

.skills-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colonnes */
  gap: 0.75rem; /* Espace entre les éléments */
  margin: 0 0 1.5rem;
}

.skill-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--foreground);
  transition: color 0.3s ease;
}

.skill-item:hover {
  color: var(--pink);
}

.contact-btn {
  background-color: var(--pink);
  border: none;
  color: var(--background);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.2);
}

.social-icon {
  font-size: 1.5rem;
  margin: 0 0.75rem;
  color: var(--foreground);
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--pink);
}

.powered {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.powered:hover {
  filter: grayscale(0%);
}
</style>
